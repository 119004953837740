@import"./icons.scss";
@import"./mermaid.min.css";

//import scss light mode
@import"./app.scss";
@import"./bootstrap.scss";

// rtl Direction
// @import "./custom/rtl/bootstrap-rtl";
// @import "./custom/rtl/components-rtl";
// @import "./custom/rtl/float-rtl";
// @import "./custom/rtl/general-rtl";
// @import "./custom/rtl/pages-rtl";
// @import "./custom/rtl/plugins-rtl";
// @import "./custom/rtl/spacing-rtl";
// @import "./custom/rtl/structure-rtl";
// @import "./custom/rtl/text-rtl";


.flatpickr-weekday {
    background: #3980c0;
}

