.video-call-screen {
  position: relative;
  width: 100%;
  height: 74vh;
  background-color: #000;
}

@keyframes ring-animation {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  50% {
    transform: scale(1);
    opacity: 0.5;
  }
  100% {
    transform: scale(2);
    opacity: 0;
  }
}

.ring {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100px;
  height: 100px;
  border: 6px solid #00FF00; // Green color
  border-radius: 50%;
  animation: ring-animation 2s infinite;
}
